import { Component, OnInit, Inject, inject } from '@angular/core';
import {
  AgenziaRefModel,
  MenuService,
  MessageService,
  ProfileModel,
  createWhatsappSession,
  environment,
  getProfile,
  getProfileWhatsapp,
  getWhatsappQrCode,
  isAdmin,
  loadAgenzieRef,
  loadAttiAcquistoMissingCount,
  loadGlobalFilters,
  loadProfile,
  loadSharedData,
  loadWhatsappQrCode,
  removeWhatsappSession,
  selectAgenziaRefList,
  selectGlobalFilters,
  setGlobalFilters,
  updateProfileWhatsappStatus,
} from '@tuacar-workspace/core';
import { selectRouter } from './../+state/root.selectors';
import { logout } from '../+state/root.actions';
import { Observable, Subscription, interval, timer } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { DOCUMENT } from '@angular/common';
import { SwUpdate } from '@angular/service-worker';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import * as Ably from 'ably';

@Component({
  selector: 'tuacar-home',
  template: `
    <!-- <tuacar-auth-button></tuacar-auth-button>
{{ auth.isLoading$ | async }}

<ul *ngIf="auth.user$ | async as user">
  <li>{{ user.name }}</li>
  <li>{{ user.family_name }}</li>
  <li>{{ user.email }}</li>
  <li>{{ user.picture }}</li>
  <li>{{ user | json }}</li>
</ul>

<ul *ngIf="auth.idTokenClaims$ | async as token">
  <li>
    {{ token | json }}
  </li>
  <li>roles: {{ token['https://manager.tua-car.it/roles'] }}</li>
</ul> -->
    <div class="__stg_env" *ngIf="env === 'staging'">
      <strong>
        <span nz-typography style="color: #ffffff"
          >AMBIENTE DI TEST</span
        ></strong
      >
    </div>
    <div *ngIf="(profile$ | async) === undefined" class="loading">
      <img
        src="https://assets.tuacar.it/common/tuacar_manager_logo_blue_blue.svg"
        alt="logo"
      />
    </div>
    <nz-layout
      style="min-height: 100vh; overflow: hidden"
      *ngIf="profile$ | async"
    >
      <nz-sider
        nzWidth="260px"
        [(nzCollapsed)]="isCollapsed"
        (nzCollapsedChange)="onChangeMenuCollapse($event)"
      >
        <!-- <nz-affix [nzOffsetTop]="10"> -->
        <div
          class="sidebar-logo"
          [routerLink]="[
            {
              outlets: {
                primary: ['erp'],
                menu: [],
              },
            },
          ]"
        >
          <img
            src="https://assets.tuacar.it/common/tuacar_manager_logo_white_white.svg"
            alt="logo"
          />
          <!-- <span nz-typography nzType="secondary">v. {{ version }} </span> -->
        </div>

        <div
          style="
            padding-left:10px;
            padding-right:10px;
            "
        >
          <nz-select
            id="agenzia"
            nzShowSearch
            nzAllowClear="true"
            nzPlaceHolder="Tutte le agenzie"
            style="width: 100%"
            [ngModel]="selectedAgenzia"
            (ngModelChange)="onAgenziaChange($event)"
            [compareWith]="compareFnCode"
            [nzDisabled]="hasOnlyOneAgenzia"
            [nzCustomTemplate]="customTemplate"
          >
            <nz-option
              nzCustomContent
              *ngFor="let o of agenzie$ | async"
              [nzLabel]="o.description"
              [nzValue]="o"
            >
              <span nz-typography>{{ o.description }}</span>

              <span
                nz-icon
                nzType="shop"
                nzTheme="outline"
                *ngIf="o.isPrincipale && !o.isFranchisor"
              ></span>

              <span
                nz-icon
                nzType="home"
                nzTheme="outline"
                *ngIf="o.isFranchisor"
              ></span>
            </nz-option>
          </nz-select>
          <ng-template #customTemplate let-selected>
            <span nz-typography>{{ selected.nzValue.description }}</span>
            <span
              nz-icon
              nzType="shop"
              nzTheme="outline"
              *ngIf="
                selected.nzValue.isPrincipale && !selected.nzValue.isFranchisor
              "
            ></span>
            <span
              nz-icon
              nzType="home"
              nzTheme="outline"
              *ngIf="selected.nzValue.isFranchisor"
            ></span>
          </ng-template>
        </div>
        <!-- </nz-affix> -->

        <router-outlet name="menu"></router-outlet>
      </nz-sider>
      <nz-layout style="margin-left: 260px">
        <nz-affix [nzOffsetTop]="0">
          <nz-header>
            <div class="app-header">
              <ul
                nz-menu
                [nzMode]="'horizontal'"
                style="line-height: 64px"
                *ngIf="profile$ | async as profile"
              >
                <!-- <li style="display: inline-block">
                <span class="header-trigger" (click)="changeMenuCollapse()">
                  <i
                    class="trigger"
                    nz-icon
                    [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
                  ></i>
                </span>
              </li> -->
                <li
                  nz-menu-item
                  nzMatchRouter
                  [routerLink]="[
                    {
                      outlets: {
                        primary: ['erp'],
                        menu: ['erp'],
                      },
                    },
                  ]"
                  [nzSelected]="
                    (router$ | async)?.state?.root?.firstChild?.routeConfig
                      ?.path === 'erp'
                  "
                >
                  ERP
                </li>
                <!-- <li
            nz-menu-item
            [routerLink]="[
              {
                outlets: {
                  primary: ['ciclo-passivo'],
                  menu: ['ciclo-passivo']
                }
              }
            ]"
            [nzSelected]="
              (router$ | async)?.state?.root?.firstChild?.routeConfig?.path ===
              'ciclo-passivo'
            "
          >
            Ciclo passivo
          </li> -->
                <li
                  nz-menu-item
                  (click)="openExternalSite('https://crm.tuacar.it')"
                >
                  CRM
                </li>
                <li
                  nz-menu-item
                  nzMatchRouter
                  [routerLink]="[
                    {
                      outlets: {
                        primary: ['anagrafiche'],
                        menu: ['anagrafiche'],
                      },
                    },
                  ]"
                  [nzSelected]="
                    (router$ | async)?.state?.root?.firstChild?.routeConfig
                      ?.path === 'anagrafiche'
                  "
                >
                  Anagrafiche
                </li>
                <li
                  nz-menu-item
                  nzMatchRouter
                  [routerLink]="[
                    {
                      outlets: {
                        primary: ['statistiche'],
                        menu: ['statistiche'],
                      },
                    },
                  ]"
                  [nzSelected]="
                    (router$ | async)?.state?.root?.firstChild?.routeConfig
                      ?.path === 'statistiche'
                  "
                >
                  Statistiche
                </li>
                <li
                  *ngIf="isAdmin$$()"
                  nz-menu-item
                  nzMatchRouter
                  [routerLink]="[
                    {
                      outlets: {
                        primary: ['settings'],
                        menu: ['settings'],
                      },
                    },
                  ]"
                  [nzSelected]="
                    (router$ | async)?.state?.root?.firstChild?.routeConfig
                      ?.path === 'settings'
                  "
                  nzIcon="setting"
                >
                  Impostazioni
                </li>

                <!-- Link esterni -->
                <li nz-submenu nzTitle="Link esterni">
                  <ul>
                    <li nz-menu-item (click)="openWebSite()">
                      TuaCar sito web
                    </li>
                    <li
                      nz-menu-item
                      (click)="
                        openExternalSite(
                          'https://ari-ocp.compassonline.it/dealer-iam/app/login'
                        )
                      "
                    >
                      Compass
                    </li>
                    <li
                      nz-menu-item
                      (click)="
                        openExternalSite('https://mind.handfordealer.com')
                      "
                    >
                      Hello Driver Assicurazioni
                    </li>
                    <li
                      nz-menu-item
                      (click)="
                        openExternalSite(
                          'https://automotive.wefox.com/login?p=Lw%3D%3D'
                        )
                      "
                    >
                      Nais
                    </li>
                    <li
                      nz-menu-item
                      (click)="
                        openExternalSite(
                          'https://salesportal.mawdy.com/IT/it/0/access/login'
                        )
                      "
                    >
                      Mapfre
                    </li>
                    <li
                      nz-menu-item
                      (click)="
                        openExternalSite(
                          'https://gestionale.garanziamec.it/gestionale/login'
                        )
                      "
                    >
                      MEC
                    </li>
                    <li
                      nz-menu-item
                      (click)="openExternalSite('https://spoki.app/login')"
                    >
                      Spoki
                    </li>
                    <li
                      nz-menu-item
                      (click)="
                        openExternalSite('https://myarea.autoscout24.it')
                      "
                    >
                      Autoscout
                    </li>
                    <li
                      nz-menu-item
                      (click)="
                        openExternalSite(
                          'https://www.autoscout24.it/valutazione-auto'
                        )
                      "
                    >
                      Valutazione Autoscout
                    </li>
                    <li
                      nz-menu-item
                      (click)="
                        openExternalSite(
                          'https://areariservata.subito.it/login_form'
                        )
                      "
                    >
                      Subito
                    </li>
                    <li
                      nz-menu-item
                      (click)="openExternalSite('https://www.conformgest.it')"
                    >
                      Conformgest
                    </li>
                    <li
                      nz-menu-item
                      (click)="
                        openExternalSite(
                          'https://www.dbeasy.it/accesso_clienti_aree_riservate/index?language_id=1'
                        )
                      "
                    >
                      Deutsche Bank
                    </li>
                    <li
                      nz-menu-item
                      (click)="
                        openExternalSite(
                          'https://portaledealer.santanderconsumer.it/login'
                        )
                      "
                    >
                      Santander
                    </li>
                    <li
                      nz-menu-item
                      (click)="
                        openExternalSite(
                          'https://businessapp.b2b.trustpilot.com/dashboard'
                        )
                      "
                    >
                      Trustpilot
                    </li>
                    <li
                      nz-menu-item
                      (click)="
                        openExternalSite('https://www.automobile.it/accedi')
                      "
                    >
                      Automobile.it
                    </li>
                  </ul>
                </li>

                <li
                  nz-menu-item
                  (click)="
                    openExternalSite('https://app.rent4business.it/tuarent')
                  "
                >
                  Tuarent
                </li>

                <li style="display: inline-block; position: absolute; right: 0">
                  <div
                    style="background-color: unset !important; display:inline-block"
                  >
                    @let profileWhatsapp = profileWhatsapp$ | async;
                    @if (
                      profileWhatsapp?.status === 'STARTING' ||
                      profileWhatsapp?.status === 'SCAN_QR_CODE'
                    ) {
                      <span
                        nz-icon
                        nzType="whats-app"
                        nzTheme="outline"
                        class="__whatsappButton"
                        style="color: #FFA81E"
                        (click)="openWhatsappSettings()"
                      ></span>
                    } @else if (profileWhatsapp?.status === 'WORKING') {
                      <span
                        nz-icon
                        nzType="whats-app"
                        nzTheme="outline"
                        class="__whatsappButton"
                        style="color: #54d365"
                        (click)="openWhatsappSettings()"
                      ></span>
                    } @else if (profileWhatsapp?.status === 'FAILED') {
                      <span
                        nz-icon
                        nzType="whats-app"
                        nzTheme="outline"
                        class="__whatsappButton"
                        style="color: #FF4B4B"
                        (click)="openWhatsappSettings()"
                      ></span>
                    } @else {
                      <span
                        nz-icon
                        nzType="whats-app"
                        nzTheme="outline"
                        class="__whatsappButton"
                        style="color: #CCCCCC"
                        (click)="openWhatsappSettings()"
                      ></span>
                    }
                  </div>
                  <div
                    style="background-color: unset !important; display:inline-block"
                  >
                    <div
                      style="margin-left: 8px; margin-right: 30px"
                      nz-dropdown
                      [nzDropdownMenu]="menu"
                      nzTrigger="hover"
                    >
                      <span>v. {{ version }} &nbsp; </span>
                      <nz-avatar
                        *ngIf="!profile.picture"
                        nzSize="large"
                        nzIcon="user"
                      ></nz-avatar>
                      <nz-avatar
                        *ngIf="profile.picture"
                        [nzSize]="'large'"
                        [nzSrc]="profile.picture"
                      >
                      </nz-avatar>
                      <span style="margin-left: 4px"
                        >{{ profile.firstName }} {{ profile.lastName }}</span
                      >
                    </div>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                      <ul nz-menu nzSelectable>
                        <li nz-menu-item class="__overlayMenu">
                          {{ profile.tenantDescription }}
                        </li>
                        <li nz-menu-divider></li>
                        <li nz-menu-item (click)="logout()">Esci</li>
                        <li nz-menu-divider></li>
                        <li
                          nz-menu-item
                          class="__overlayMenu"
                          *ngIf="isAdmin$$()"
                        >
                          <nz-tag nzColor="success">Administrator</nz-tag>
                        </li>
                        <ng-container *ngFor="let agenzia of profile.agenzie">
                          <li nz-menu-item class="__overlayMenu">
                            <nz-tag nzColor="success">{{
                              agenzia.ruolo
                            }}</nz-tag>
                          </li>
                          <li
                            nz-menu-item
                            class="__overlayMenu"
                            nzDisabled="true"
                          >
                            {{ agenzia.description }}
                          </li>
                          <li
                            nz-menu-item
                            class="__overlayMenu"
                            nzDisabled="true"
                            *ngIf="agenzia.indirizzo"
                          >
                            {{ agenzia.indirizzo }}, {{ agenzia.cap }}
                            {{ agenzia.citta }} ({{ agenzia.provincia }})
                          </li>
                          <li nz-menu-divider></li>
                        </ng-container>
                      </ul>
                    </nz-dropdown-menu>
                  </div>
                </li>
              </ul>
            </div>
          </nz-header>
        </nz-affix>
        <nz-content>
          <!-- <div class="inner-content"> -->
          <router-outlet></router-outlet>
          <!-- </div> -->
        </nz-content>
      </nz-layout>
    </nz-layout>

    <nz-modal
      [nzVisible]="isVisibleWhatsappSettings"
      nzTitle="Impostazioni Whatsapp"
      nzWidth="500px"
      (nzOnCancel)="handleCancelWhatsappSettings()"
    >
      <ng-container *nzModalContent>
        <div nz-row nzJustify="center" nzAlign="top" style="text-align: center">
          <div nz-col nzSpan="24">
            @let profileWhatsapp = profileWhatsapp$ | async;
            @if (
              !profileWhatsapp?.status ||
              profileWhatsapp?.status === 'NOT_FOUND' ||
              profileWhatsapp?.status === 'STOPPED'
            ) {
              <button nz-button nzType="primary" (click)="linkWhatsapp()">
                Collega whatsapp
              </button>
            }
            @if (profileWhatsapp?.status === 'STARTING') {
              <button nz-button nzType="primary" nzLoading="true">
                Collegamento in corso...
              </button>
            }
            @if (profileWhatsapp?.status === 'SCAN_QR_CODE') {
              <button nz-button nzType="primary" (click)="showWhatsappQrCode()">
                Rigenera QR CODE
              </button>
              <div>
                <img [src]="whatsappQrCode$ | async" />
              </div>
            }
            @if (profileWhatsapp?.status === 'WORKING') {
              <ng-container *ngIf="profileWhatsapp?.number">
                <!-- <span nz-typography
                  >Nome: <code>{{ profileWhatsapp?.name }}</code></span
                >
                <br /> -->
                <span nz-typography
                  >Numero:
                  <code>{{
                    profileWhatsapp?.number?.replace('@c.us', '')
                  }}</code></span
                >
                <br />
                <br />
              </ng-container>
              <button nz-button nzType="primary" (click)="unlinkWhatsapp()">
                Scollega whatsapp
              </button>
            }
            @if (profileWhatsapp?.status === 'FAILED') {
              <ng-container *ngIf="profileWhatsapp?.number">
                <!-- <span nz-typography
                  >Nome: <code>{{ profileWhatsapp?.name }}</code></span
                >
                <br /> -->
                <span nz-typography nzType="danger"
                  >ERRORE COLLEGAMENTO WHATSAPP</span
                >
                <br />
                <br />
              </ng-container>
              <button nz-button nzType="primary" (click)="unlinkWhatsapp()">
                Ripristina whatsapp
              </button>
            }
          </div>
        </div>
      </ng-container>
      <div *nzModalFooter>
        <button
          nz-button
          nzType="default"
          (click)="handleCancelWhatsappSettings()"
        >
          Esci
        </button>
      </div>
    </nz-modal>
  `,
  styles: [
    `
      :host {
        display: flex;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      .app-layout {
        height: 100vh;
      }

      .menu-sidebar {
        position: relative;
        z-index: 10;
        min-height: 100vh;
        box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
      }

      .header-trigger {
        height: 64px;
        padding: 20px 24px;
        font-size: 20px;
        cursor: pointer;
        transition:
          all 0.3s,
          padding 0s;
      }

      .trigger:hover {
        color: #1890ff;
      }

      .sidebar-logo {
        position: relative;
        height: 80px;
        padding: 3px;
        overflow: hidden;
        line-height: 64px;
        background: #001529;
        transition: all 0.3s;
        cursor: pointer;
      }

      .sidebar-logo img {
        display: inline-block;
        //height: 32px;
        // width: 32px;
        vertical-align: middle;
        width: 100%;
      }

      .sidebar-logo h1 {
        display: inline-block;
        margin: 0 0 0 20px;
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        font-family:
          Avenir,
          Helvetica Neue,
          Arial,
          Helvetica,
          sans-serif;
        vertical-align: middle;
      }

      nz-header {
        padding: 0;
        width: 100%;
        z-index: 2;
      }

      .app-header {
        position: relative;
        height: 64px;
        padding: 0;
        background: #fff;
        box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
      }

      nz-sider {
        overflow: auto;
        height: 100%;
        position: fixed;
        left: 0;
      }

      // nz-content {
      //   margin: 24px;
      // }

      .inner-content {
        padding: 24px;
        background: #fff;
        margin-bottom: 24px;
        min-height: 100%;
      }

      .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .__overlayMenu {
        color: unset;
      }

      .__overlayMenu:hover {
        background-color: unset;
        cursor: default;
      }

      .__stg_env {
        height: 14px;
        text-align: center;
        background-color: red;
        line-height: 14px;
        position: fixed;
        font-size: 14px;
        z-index: 999;
        width: 100%;
      }

      .__bold {
        font-weight: bold;
      }

      .__whatsappButton {
        margin-right: 8px;
        font-size: 32px;
        height: auto;
        vertical-align: middle;
        cursor: pointer;
      }
    `,
  ],
})
export class HomeComponent implements OnInit {
  title = 'TuaCar Manager';
  version: string;
  env: string;
  isCollapsed = false;
  loginDisplay = false;
  profile$: Observable<ProfileModel | undefined>;
  profileWhatsapp$ = this.store.pipe(select(getProfileWhatsapp));
  agenzie$ = this.store.pipe(select(selectAgenziaRefList));
  //tenants$ = this.store.pipe(select(selectTenantsRefList));
  router$ = this.store.pipe(select(selectRouter));
  globalFilters$ = this.store.pipe(select(selectGlobalFilters));
  isAdmin$$ = this.store.selectSignal(isAdmin);
  whatsappQrCode$ = this.store.pipe(select(getWhatsappQrCode));
  profilePhoto = '';
  sub: Subscription = new Subscription();
  wahaSubscription?: Ably.RealtimeChannel;

  selectedAgenzia?: AgenziaRefModel;
  hasOnlyOneAgenzia = false;
  // hasOnlyOneTenant = false;

  isVisibleWhatsappSettings = false;

  messageService = inject(MessageService);

  constructor(
    private store: Store,
    private menuService: MenuService,
    @Inject(DOCUMENT) public document: Document,
    private swUpdate: SwUpdate,
    private modal: NzModalService,
    private router: Router,
  ) {
    this.version = environment.version;

    this.env = environment.env;

    // this.service.getProfile().subscribe((profile) => {
    //   console.log(profile);
    // });

    // this.auth.getAccessTokenSilently().subscribe((token) => {
    //   console.log(token);
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   const tokenInfo = jwt_decode(token) as any; // decode token
    //   //const expireDate = tokenInfo.exp; // get token expiration dateTime
    //   console.log(tokenInfo.permissions); // show decoded token object in console
    // });

    this.profile$ = this.store.pipe(select(getProfile));

    this.sub.add(
      this.profile$.subscribe((profile) => {
        if (profile) {
          // if admin or erp-backoffice
          let isBackOffice = false;
          profile?.agenzie.forEach((agenzia) => {
            if (agenzia.ruolo.includes('erp-backoffice')) {
              isBackOffice = true;
            }
          });

          if (this.isAdmin$$() || isBackOffice) {
            timer(0, 300000).subscribe(() => {
              this.store.dispatch(loadAttiAcquistoMissingCount());
            });
          }
        }
      }),
    );

    this.sub.add(
      this.agenzie$.subscribe((agenzie) => {
        this.hasOnlyOneAgenzia = agenzie.length === 1;

        if (this.hasOnlyOneAgenzia) {
          this.selectedAgenzia = agenzie[0];
          this.store.dispatch(
            setGlobalFilters({
              globalFilters: { agenzia: this.selectedAgenzia },
            }),
          );
        }
      }),
    );

    // this.sub.add(
    //   this.tenants$.subscribe((tenants) => {
    //     this.hasOnlyOneTenant = tenants.length === 1;

    //     if (this.hasOnlyOneTenant) {
    //       this.selectedTenant = tenants[0];
    //       this.store.dispatch(
    //         setGlobalFilters({
    //           globalFilters: { tenant: this.selectedTenant },
    //         }),
    //       );
    //     }
    //   }),
    // );

    this.sub.add(
      this.globalFilters$.subscribe((globalFilters) => {
        if (globalFilters?.agenzia) {
          this.selectedAgenzia = globalFilters.agenzia;
        }
        this.store.dispatch(loadAttiAcquistoMissingCount());
      }),
    );

    const updatesAvailable = this.swUpdate.versionUpdates;

    if (this.swUpdate.isEnabled) {
      // Required to enable updates on Windows and ios.
      this.swUpdate.activateUpdate().then();

      interval(30000).subscribe(() => {
        this.swUpdate.checkForUpdate().then(() => {
          // console.log('checking for updates');
        });
      });
    }

    this.sub.add(
      updatesAvailable.subscribe(async (evt) => {
        //console.log('UpdateService: versionUpdates', evt);
        switch (evt.type) {
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${evt.version.hash}`);
            break;
          case 'VERSION_READY':
            console.log(`Current app version: ${evt.currentVersion.hash}`);
            console.log(
              `New app version ready for use: ${evt.latestVersion.hash}`,
            );
            await this.swUpdate.activateUpdate();
            this.modal.confirm({
              nzTitle: 'Nuova versione disponibile',
              nzContent: `Caricare l'ultima versione?`,
              nzOnOk: () =>
                new Promise(() => {
                  window.location.reload();
                }).catch(() => console.log('Oops errors!')),
            });
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(
              `Failed to install app version '${evt.version.hash}': ${evt.error}`,
            );
            break;
        }
      }),
    );

    this.sub.add(
      this.profile$.subscribe((profile) => {
        if (profile && !this.wahaSubscription) {
          console.log('ably subscription');
          this.wahaSubscription = this.messageService.channelWaha;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.wahaSubscription?.subscribe((message: any) => {
            const data = JSON.parse(message.data);
            if (data.Metadata.UserId === profile.userId) {
              console.log('Waha message', data);
              this.store.dispatch(
                updateProfileWhatsappStatus({
                  sessionId: data.Session,
                  name: data.Me?.PushName,
                  number: data.Me?.Id,
                  status: data.Payload.Status,
                }),
              );
            }
          });
        }
      }),
    );
  }
  ngOnInit(): void {
    this.store.dispatch(loadProfile());
    this.store.dispatch(loadSharedData());
    this.store.dispatch(loadAgenzieRef());
    this.store.dispatch(loadGlobalFilters());
  }

  onAgenziaChange(agenzia: AgenziaRefModel) {
    this.selectedAgenzia = agenzia;
    this.store.dispatch(
      setGlobalFilters({
        globalFilters: {
          agenzia: this.selectedAgenzia,
        },
      }),
    );
  }

  logout() {
    this.store.dispatch(setGlobalFilters({ globalFilters: {} }));
    this.store.dispatch(logout());
  }

  onChangeMenuCollapse(isCollapsed: boolean) {
    this.menuService.setCollapsed(isCollapsed);
  }

  changeMenuCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.menuService.setCollapsed(this.isCollapsed);
  }

  // openCrm(): void {
  //   this.document.location.href = environment.crmUrl;
  // }

  openWebSite(): void {
    window.open(environment.webSiteUrl, '_blank');
  }

  openExternalSite(url: string): void {
    window.open(url, '_blank');
  }

  openWhatsappSettings(): void {
    this.isVisibleWhatsappSettings = true;
  }

  handleCancelWhatsappSettings(): void {
    this.isVisibleWhatsappSettings = false;
  }

  linkWhatsapp(): void {
    this.store.dispatch(createWhatsappSession());
  }

  unlinkWhatsapp(): void {
    this.store.dispatch(removeWhatsappSession());
  }

  showWhatsappQrCode(): void {
    this.store.dispatch(loadWhatsappQrCode());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  compareFnCode = (o1: any, o2: any) =>
    o1 && o2 ? o1.code === o2.code : o1 === o2;
}
